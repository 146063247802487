<template>
  <section id="dashboard-ecommerce">
    {{'Dashboard'}}
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
